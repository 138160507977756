<template>
  <div class="wish-detail">
    <!-- <van-nav-bar title="我的心愿" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="form">
        <van-cell-group>
          <van-field
            v-model="username"
            center
            clearable
            label="许愿人"
            placeholder="请输入名字"
            required
            ref="user"
          >
            <!-- <template #button>
              <van-button
                size="small"
                round
                type="info"
                @click="forOther"
                :class="[readFlag?'':'bgColor']"
              >{{readFlag?'代人许愿':'自己许愿'}}</van-button>
            </template>-->
          </van-field>
          <van-field v-model="phone" required label="手机号" placeholder="请输入手机号" />
          <van-field
            v-model="endTime"
            required
            readonly
            clickable
            label="截至时间"
            placeholder="请输入截至时间"
            @click="flag=true"
          />
          <van-popup v-model="flag" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="confirm"
              @cancel="flag=false"
            />
          </van-popup>
          <van-field v-model="wishTitle" required label="许愿标题" placeholder="请输入许愿标题" />
          <van-field
            v-model="content"
            required
            rows="4"
            autosize
            label="许愿内容"
            type="textarea"
            placeholder="填写你的心愿内容"
          />
        </van-cell-group>
        <!-- multiple
          :max-count="1" -->
        <van-uploader
          v-model="fileList"
          :max-count="1"
          multiple
          :before-read="beforeRead"
          :after-read="afterRead"
          result-type="dataUrl"
        />
      </div>
      <button v-if="buttonShow" class="post" @click="postWish">许愿</button>
      <button v-else class="postDisabled">许愿</button>
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import qs from 'qs';
import { formateTime } from '@/utils/util';
import { uploadFile } from '@/api/tinyWish';
import { screenTinyWishWish } from '@/api/bigScreen';

export default {
  data() {
    return {
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      //切换时间选择框
      flag: false,
      //只读
      readFlag: true,
      username: this.$route.params.user,
      phone: '',
      endTime: '',
      wishTitle: '',
      content: '',
      //图片
      fileList: [],
      //代替许愿背景颜色控制
      count: 0,
      buttonShow: true
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'tinyWish' });
    },
    // 确定选择的时间
    confirm(value) {
      const val = formateTime(value).split(' ')[0];
      this.flag = !this.flag;
      this.endTime = val;
    },
    // 代人许愿
    forOther() {
      if (this.readFlag) {
        this.username = '';
        this.readFlag = !this.readFlag;
        return false;
      }
      this.username = localStorage.getItem('nickName');
      this.readFlag = !this.readFlag;
    }, // 上传前校验
    // 上传前校验
    beforeRead(file) {
      console.log(this.fileList);
      if (Array.isArray(file)) {
        let notType = false;
        file.forEach(item => {
          if (
            item.type !== 'image/jpeg' &&
            item.type !== 'image/png' &&
            item.type !== 'image/gif'
          ) {
            notType = true;
          }
        });
        if (notType) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
        if (file.length > 1 - this.fileList.length) {
          Notify({
            type: 'warning',
            message: '您上传的张数超过上限，请重新选择'
          });
          return false;
        }
      } else {
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif'
        ) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
      }

      return true;
    },
    // 上传服务器
    afterRead(file) {
      const this_ = this;
      if (Array.isArray(file)) {
        file.forEach(fileItem => {
          console.log(fileItem, this.fileList);
          fileItem.status = 'uploading';
          fileItem.message = '上传中...';
          const formdata = new FormData();
          formdata.append('file', file.file);
          lrz(fileItem.file, {})
            .then(async function(rst) {
              //成功时执行
              console.log('压缩成功');
              const formdata = rst.formData;
              await uploadFile(formdata)
                .then(res => {
                  res = res.data;
                  if (res.success) {
                    fileItem.status = 'done';
                    fileItem.url = res.data.link;
                    Notify({ type: 'success', message: '上传成功' });
                  } else {
                    fileItem.status = 'failed';
                    fileItem.message = '上传失败';
                    Notify({ type: 'warning', message: '上传失败' });
                  }
                })
                .catch(err => {
                  fileItem.status = 'failed';
                  fileItem.message = '上传失败';
                  Notify({ type: 'warning', message: '上传失败' });
                  console.log(err);
                });
            })
            .catch(function(error) {
              Notify({ type: 'warning', message: '图片压缩失败' });
              //失败时执行
            })
            .always(function() {
              //不管成功或失败，都会执行
            });
        });
      } else {
        console.log(file, this.fileList);
        file.status = 'uploading';
        file.message = '上传中...';
        // const formdata = new FormData();
        // formdata.append('file', file.file);
        lrz(file.file, {})
          .then(function(rst) {
            //成功时执行
            console.log('压缩成功');
            const formdata = rst.formData;
            uploadFile(formdata)
              .then(res => {
                res = res.data;
                if (res.success) {
                  file.status = 'done';
                  file.url = res.data.link;
                  Notify({ type: 'success', message: '上传成功' });
                } else {
                  this_.fileList = this_.fileList.slice(
                    0,
                    this_.fileList.length - 1
                  );
                  Notify({ type: 'warning', message: '上传失败' });
                }
              })
              .catch(err => {
                this_.fileList = this_.fileList.slice(
                  0,
                  this_.fileList.length - 1
                );
                Notify({ type: 'warning', message: '上传失败' });
                console.log(err);
              });
          })
          .catch(function(error) {
            Notify({ type: 'warning', message: '图片压缩失败' });
            //失败时执行
          })
          .always(function() {
            //不管成功或失败，都会执行
          });
      }
    },
    // 发布心愿
    postWish() {
      // const fileList = this.fileList.map(item => item.url);
      let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!reg.test(this.phone)) {
        Notify({ type: 'warning', message: '请输入正确的手机格式' });
        return false;
      }
      if (!this.username) {
        Notify({ type: 'warning', message: '请输入许愿人' });
        return false;
      }
      if (!this.phone) {
        Notify({ type: 'warning', message: '请输入手机号' });
        return false;
      }
      if (!this.endTime) {
        Notify({ type: 'warning', message: '请输入截至时间' });
        return false;
      }
      if (!this.wishTitle) {
        Notify({ type: 'warning', message: '请输入许愿标题' });
        return false;
      }
      if (!this.content) {
        Notify({ type: 'warning', message: '请输入许愿内容' });
        return false;
      }
      const fileList = this.fileList;
      let imgString = [];
      fileList.forEach(item => {
        if ((item.status = 'done')) {
          imgString.push(item.url);
        }
      });
      const imgArray = [];
      imgString.forEach(item => {
        if (item) {
          imgArray.push(item);
        }
      });
      screenTinyWishWish({
        wishPersonName: this.username,
        wishPersonMobile: this.phone,
        endTime: this.endTime,
        wishTitle: this.wishTitle,
        description: this.content,
        picture: imgArray.join(',')
      })
        .then(res => {
          if (res.success) {
            Notify({ type: 'success', message: '许愿成功' });
            this.buttonShow = false;
            // setTimeout(() => {
            //   window.open('about:blank', '_self').close();
            // }, 1000);
            // setTimeout(() => {
            //   this.$router.go(-1);
            // }, 500);
          } else {
            Notify({ type: 'success', message: '许愿失败' });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    // this.username = JSON.parse(localStorage.getItem('user')).realName;
    // this.phone = localStorage.getItem('userName');
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    background: #f3f3f3;
    flex: 1;
    overflow: auto;
    .form {
      margin-top: 10 * $px;
      background: #fff;
      .van-uploader {
        padding: 24 * $px 0 30 * $px 24 * $px;
      }
      .bgColor {
        background: rgba(255, 25, 47, 1);
        border: 2 * $px solid #ff192f;
      }
    }
    .post {
      width: 690 * $px;
      height: 88 * $px;
      background: rgba(255, 25, 47, 1);
      border: 0;
      font-size: 32 * $px;
      color: rgba(255, 255, 255, 1);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
    .postDisabled {
      width: 690 * $px;
      height: 88 * $px;
      background: rgb(213, 214, 217);
      border: 0;
      font-size: 32 * $px;
      color: rgb(133, 134, 136);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
  }
}
</style>